<template>
  <div class="menu-indicadores">
      <p>Proceso Electoral</p>
      <p>Ejercicio del Cargo</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .menu-indicadores{
    width:90%;
    height: calc( 100vh - 160px);
    margin:10px auto;
    background-color: purple;
    display: flex; align-items:center; justify-content:space-around;

    & p {
      width:200px;
      height:200px;
      background-color: #fff;
      display:flex; align-items:center; justify-content:center;
    }

  }

</style>